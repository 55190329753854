import { SLOTS } from '../../lib/slots';
import { EditorAppContext } from '../types';
import { WidgetBuilder } from '@wix/app-manifest-builder/dist/types';
import { getSlot } from '../getSlot';
import { productsAppDefId } from '../constants/appDefIds';
import { productWidgetComponentId } from '../constants/componentIds';
import { FlowAPI } from '@wix/yoshi-flow-editor';
import { createSettingsClient } from '@wix/search-settings-client';

export const installProductsWidget = async (
  widgetBuilder: WidgetBuilder,
  appContext: EditorAppContext,
  flowAPI: FlowAPI,
) => {
  widgetBuilder.slots().set({
    [SLOTS.products.id]: {
      displayName: SLOTS.products.displayName,
      interfaces: [],
    },
  });

  const productsSlot = await getSlot(appContext.editorSDK, SLOTS.products.id);

  if (productsSlot && !productsSlot.pluginInfo) {
    await appContext.editorSDK.tpa.widgetPlugins.addWidgetPlugin(
      appContext.appDefinitionId,
      {
        slotCompRef: productsSlot.compRef!,
        widgetPluginPointer: {
          appDefinitionId: productsAppDefId,
          widgetId: productWidgetComponentId,
        },
      },
    );
    setProductSlotInstalled(flowAPI);
  }
};

const setProductSlotInstalled = async (flowAPI: FlowAPI) => {
  const httpClient = flowAPI.httpClient;
  const settingsClient = createSettingsClient({
    httpClient,
  });
  await settingsClient.set('isProductSlotInstalled', true);
};
