import { AppManifestBuilder } from '@wix/yoshi-flow-editor';

import { openSearchResultsWidgetSettingsPanel } from '../searchResultsWidgetSettings';
import { ControllerType, EditorAppContext, WixCodeRole } from '../types';

export function overrideSearchBoxGfpp(
  appContext: EditorAppContext,
  appManifestBuilder: AppManifestBuilder,
): AppManifestBuilder {
  const { translate, reportError } = appContext;

  return appManifestBuilder
    .configureController(ControllerType.SearchApp, (controllerBuilder) => {
      controllerBuilder.configureConnectedComponents(
        WixCodeRole.SearchBox,
        (connectedComponentsBuilder) => {
          connectedComponentsBuilder.gfpp().set('mainAction2', {
            label: translate('searchBox.gfpp.manageSearchResults'),
            async onClick() {
              try {
                await openSearchResultsWidgetSettingsPanel(appContext);
              } catch (e) {
                reportError(e);
              }
            },
          });
        },
      );
    })
    .configureWidget(ControllerType.SearchApp, (widgetBuilder) => {
      widgetBuilder.gfpp().set('connect', {
        behavior: 'HIDE',
      });
      widgetBuilder.configureConnectedComponents(
        WixCodeRole.SearchBox,
        (connectedComponentsBuilder) => {
          connectedComponentsBuilder
            .gfpp()
            .set('mainAction2', {
              label: translate('searchBox.gfpp.manageSearchResults'),
              async onClick() {
                try {
                  await openSearchResultsWidgetSettingsPanel(appContext);
                } catch (e) {
                  reportError(e);
                }
              },
            })
            .set('connect', {
              behavior: 'HIDE',
            });
        },
      );
    });
}
